import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { renderEventContent } from '../../calendar/DashboardCalendarInfo';
import "../../calendar/style.css";
import moment from 'moment';
import { useNavigate } from "react-router-dom";
import { TEXTS } from '../../../constants';
import getCurrentUser from '../../user/getCurrentUserapi';
import roLocale from "@fullcalendar/core/locales/ro";
import { loadAllOpportunityPaginated } from '../../../redux/rtk/features/crm/opportunity/opportunitySlice';
import { loadAllContactPaginated } from '../../../redux/rtk/features/crm/contact/contactSlice';
import { loadAllContactStage } from '../../../redux/rtk/features/crm/ContactStage/contactStageSlice';
import { loadAllOpportunityStage } from '../../../redux/rtk/features/crm/opportunityStage/opportunityStageSlice';
import { loadAllStaff } from '../../../redux/rtk/features/user/userSlice';
import { FiFilter } from 'react-icons/fi';
import { Select } from 'antd';

const { Option } = Select;

export default function CalendarBar() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [clientEvents, setClientEvents] = useState([]);
    const [opportunityEvents, setOpportunityEvents] = useState([]);
    const [selectedFilter, setSelectedFilter] = useState('all');
    const [currentUser, setCurrentUser] = useState(null);
    const [loading, setLoading] = useState(true);

    const contactList = useSelector((state) => state.contact.list);
    const opportunityList = useSelector((state) => state.opportunity.list);
    const contactLoading = useSelector((state) => state.contact.loading);
    const loadingOpportunity = useSelector((state) => state.opportunity.loading);

    useEffect(() => {
        // Fetch current user data
        (async () => {
            try {
                const user = await getCurrentUser();
                setCurrentUser(user);
            } catch (error) {
                console.error("Error fetching current user:", error.message);
            } finally {
                setLoading(false);
            }
        })();
    }, []);

    useEffect(() => {
        // Fetch data for contacts, opportunities, stages, and staff
        if (!contactLoading && !loadingOpportunity) {
            dispatch(loadAllOpportunityPaginated({ status: true, count: 999 }));
            dispatch(loadAllContactPaginated({ status: true, count: 999 }));
            dispatch(loadAllContactStage());
            dispatch(loadAllOpportunityStage());
            dispatch(loadAllStaff({ status: true }));
        }
    }, [dispatch]);

    useEffect(() => {
        if (!currentUser || loading || contactLoading || loadingOpportunity) return;

        const isAdminOrManager = currentUser.department.id === 1 || currentUser.department.id === 3;

        const clientEvents = isAdminOrManager
            ? contactList.flatMap(contact => {
                const events = [
                ];

                if (contact.contactStage.contactStageName === "Măsurare" && contact.contactCloseDate) {
                    events.push({
                        id: contact.id,
                        title: `${contact.firstName} ${contact.lastName} - Măsurare`,
                        start: moment(contact.contactCloseDate).toDate(),
                        type: "client",
                    });
                }

                if (contact.contactStage.contactStageName === "Prezentare" && contact.contactCloseDate) {
                    events.push({
                        id: contact.id,
                        title: `${contact.firstName} ${contact.lastName} - Prezentare`,
                        start: moment(contact.contactCloseDate).toDate(),
                        type: "client",
                    });
                }

                if (contact.contactDeadline) {
                    events.push({
                        id: contact.id,
                        title: `${contact.firstName} ${contact.lastName} - Deadline`,
                        start: moment(contact.contactDeadline).toDate(),
                        type: "client",
                    });
                }

                return events;
            })
            : [];

        const opportunityEvents = (currentUser.department.id === 1 || currentUser.department.id === 3)
            ? opportunityList
                .filter((opportunity, index, self) => {
                    // Filter to keep only the oldest opportunity for each contact
                    const oldestOpportunity = self
                        .filter(opp => opp.contactId === opportunity.contactId)
                        .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt) || a.id - b.id)[0];

                    return opportunity.id === oldestOpportunity.id;
                })
                .flatMap(opportunity => {
                    const contact = contactList.find(c => c.id === opportunity.contactId);
                    if (!contact) return [];

                    const events = [];

                    events.push({
                        id: opportunity.contactId,
                        title: `${contact.firstName || "Fara"} ${contact.lastName || "Client"}`,
                        start: moment(opportunity.opportunityCloseDate).toDate(),
                        deliveryDate: moment(opportunity.opportunityDeliveryDate).toDate(),
                        type: "opportunity",
                    });

                    if (opportunity.opportunityStage.opportunityStageName === "Măsurări Finale" && opportunity.opportunityCloseDate) {
                        events.push({
                            id: opportunity.contactId,
                            title: `${contact.firstName || "Fara"} ${contact.lastName || "Client"} - Măsurări Finale`,
                            start: moment(opportunity.opportunityCloseDate).toDate(),
                            type: "opportunity",
                        });
                    }

                    if (opportunity.opportunityDeliveryDate) {
                        events.push({
                            id: opportunity.contactId,
                            title: `${contact.firstName || "Fara"} ${contact.lastName || "Client"} - Livrare`,
                            start: moment(opportunity.opportunityDeliveryDate).toDate(),
                            type: "delivery",
                        });
                    }

                    return events;
                }) : [];

        setClientEvents(clientEvents);
        setOpportunityEvents(opportunityEvents);
    }, [currentUser, contactList, opportunityList, contactLoading, loadingOpportunity, loading]);

    const filteredEvents = (() => {
        switch (selectedFilter) {
            case "Masurare":
                return clientEvents.filter(event => event.title.includes("Măsurare"));
            case "Prezentare":
                return clientEvents.filter(event => event.title.includes("Prezentare"));
            case "Masurare finala":
                return opportunityEvents.filter(event => event.type === "opportunity" && event.title.includes("Măsurări Finale"));
            case "Livrare":
                return opportunityEvents.filter(event => event.type === "delivery");
            case "Deadline":
                return clientEvents.filter(event => event.title.includes("Deadline"));
            default:
                return [...clientEvents, ...opportunityEvents];
        }
    })();

    const handleEventClick = (clickInfo) => {
        const eventId = clickInfo.event.id;
        if (clickInfo.event.extendedProps.type === 'client') {
            navigate(`/admin/contact/${eventId}`);
        } else if (clickInfo.event.extendedProps.type === 'opportunity') {
            navigate(`/admin/contact/${eventId}`);
        }
        else if (clickInfo.event.extendedProps.type === 'delivery') {
            navigate(`/admin/contact/${eventId}`);
        }
    };

    if (loading || contactLoading || loadingOpportunity) return <div>Loading...</div>;

    return (
        <div className="p-4">
            <div className='container w-auto ml-12 md:col-span-1 mb-2 ant-shadow callendar-container'>
                <FullCalendar
                    plugins={[dayGridPlugin]}
                    initialView='dayGridMonth'
                    weekends={true}
                    events={filteredEvents}
                    eventContent={renderEventContent}
                    aspectRatio={2.1}
                    eventClick={handleEventClick}
                    dayCellClassNames='custom-day-cell'
                    dayMaxEventRows={2}
                    locale={roLocale}
                    key={selectedFilter}
                />
            </div>
            <div className="flex mt-4 space-x-4 ml-12">
                <button
                    onClick={() => setSelectedFilter('all')}
                    className="flex items-center px-4 py-2 bg-blue-500 text-white rounded-lg shadow-md hover:bg-blue-600"
                >
                    <FiFilter className="mr-2" />
                    Reset Filters
                </button>
                <Select
                    className="w-64"
                    value={selectedFilter}
                    onChange={setSelectedFilter}
                    placeholder="Selectează filtrul"
                >
                    <Option value="all">Toate</Option>
                    <Option value="Masurare">Măsurare</Option>
                    <Option value="Prezentare">Prezentare</Option>
                    <Option value="Masurare finala">Măsurări Finale</Option>
                    <Option value="Livrare">Livrare</Option>
                    <Option value="Deadline">Deadline</Option>
                </Select>
            </div>
        </div>
    );
}
