import { Form, Select, Button, Radio, Spin, Alert } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadAllContact } from "../../../redux/rtk/features/crm/contact/contactSlice";
import { loadAllAttachment } from "../../../redux/rtk/features/crm/attachment/crmAttachmentSlice";
import { PDFDocument, rgb } from 'pdf-lib';
import fontkit from '@pdf-lib/fontkit';
import { saveAs } from "file-saver";
import { TEXTS } from "../../../constants";
import getImageUrl from "../../../utils/getimageUrl";
import { loadAllQuote } from "../../../redux/rtk/features/crm/quote/quoteSlice";
import { loadAllContactRooms } from "../../../redux/rtk/features/crm/contactRoom/contactRoomSlice";
import * as pdfjsLib from 'pdfjs-dist/webpack';
import { addSingleOffer, loadAllOffer } from "../../../redux/rtk/features/crm/offer/offerSlice";
import { fetchSettings } from "../../../redux/rtk/features/settings/settingsSlice";
import { loadAllOpportunity } from "../../../redux/rtk/features/crm/opportunity/opportunitySlice";

export default function CreateOfertaForm({ onClose, createAs = {} }) {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [filteredOpportunities, setFilteredOpportunities] = useState([]);
    const [selectedOpportunities, setSelectedOpportunities] = useState([]);
    const [contactRooms, setContactRooms] = useState([]);
    const [loading, setLoading] = useState(false);
    const [warnings, setWarnings] = useState({});
    const [selectedContactRooms, setSelectedContactRooms] = useState([]);

    const { list: contactList, loading: contactLoading } = useSelector(state => state.contact);
    const { list: opportunityList, loading: opportunityLoading } = useSelector(state => state.opportunity);
    const { list: attachmentList, loading: attachmentLoading } = useSelector(state => state.crmAttachment);
    const { list: quoteList, loading: quoteLoading } = useSelector(state => state.quote);
    const { list: contactRoom, loading: contactRoomLoading } = useSelector(state => state.contactRoom);
    const settings = useSelector(state => state.settings);
    const maximX = settings.settings?.[0]?.maximX;
    const eurExchangeRate = settings.settings?.[0]?.eurExchangeRate;
    useEffect(() => {
        dispatch(loadAllContact());
        dispatch(loadAllOpportunity());
        dispatch(loadAllAttachment());
        dispatch(loadAllContactRooms());
        dispatch(fetchSettings());
        dispatch(loadAllQuote());
    }, [dispatch]);
    useEffect(() => {
        if (createAs?.name === "contactId" && createAs?.value && contactList.length > 0) {
            // Ensure the contact exists in the list before setting
            const contactExists = contactList.some(contact => contact.id === createAs.value);

            if (contactExists) {
                form.setFieldsValue({ contactId: createAs.value });

                // Immediately filter contact rooms
                const filteredContactRooms = contactRoom.filter(room => room.contactId === createAs.value);
                setContactRooms(filteredContactRooms);

                // Trigger contact change handler
                handleContactChange(createAs.value);
            } else {
                console.warn(`Contact with ID ${createAs.value} not found in the contact list`);
            }
        }
    }, [createAs, form, contactList, contactRoom, opportunityList]);
    useEffect(() => {
        if (opportunityLoading) {
            return true;
        }
        const newWarnings = {};
        selectedOpportunities.forEach(opportunity => {
            // Check if `opportunity` is null or doesn't have an `id`
            if (!opportunity || !opportunity.id) {
                console.warn("Invalid opportunity:", opportunity);
                return;
            }

            const attachments = attachmentList.filter(
                attachment => attachment?.opportunity?.id === opportunity.id
            );

            const hasType3 = attachments.some(
                attachment => attachment?.attachmentTypeId === 3
            );
            const hasType4 = attachments.some(
                attachment => attachment?.attachmentTypeId === 4
            );

            if (!hasType3 || !hasType4) {
                newWarnings[opportunity.id] = `Camera "${opportunity.opportunityName}" Nu are proiecte ${!hasType3 ? '2D' : ''
                    }${!hasType3 && !hasType4 ? ' și ' : ''}${!hasType4 ? '3D' : ''}.`;
            }
        });
        setWarnings(newWarnings);
    }, [selectedOpportunities, attachmentList]);

    // Function to handle contact change
    const handleContactChange = (contactId) => {
        const filteredContactRooms = contactRoom.filter(room => room.contactId === contactId);
        setContactRooms(filteredContactRooms); // Update contact rooms based on selected contact
        form.setFieldsValue({ contactRoomIds: [], opportunityIds: [] }); // Reset rooms and opportunities in the form
        setFilteredOpportunities({}); // Clear the opportunities map
    };

    const handleContactRoomChange = (contactRoomIds) => {
        if (contactRoomLoading) {
            return;
        }
        setSelectedContactRooms(contactRoomIds);

        const newFilteredOpportunities = {};
        contactRoomIds.forEach(roomId => {
            const roomOpportunities = opportunityList.filter(o => o?.contactRoomId === roomId);
            if (roomOpportunities.length > 0) {
                newFilteredOpportunities[roomId] = roomOpportunities;
            } else {
                console.warn(`No opportunities found for contact room with ID ${roomId}.`);
            }
        });

        setFilteredOpportunities(newFilteredOpportunities);
        contactRoomIds.forEach(roomId => form.setFieldsValue({ [`opportunityIds-${roomId}`]: [] }));
    };

    const handleOpportunityChange = (opportunityIds, roomId) => {
        if (opportunityLoading) {
            return;
        }

        // Validate input
        if (!opportunityIds || !roomId) {
            console.warn('Invalid opportunity selection');
            return;
        }

        const newFilteredOpportunities = opportunityIds?.reduce((acc, id) => {
            const opportunity = opportunityList.find(o => o?.id === id);
            if (opportunity) {
                acc.push({ ...opportunity, roomId });
            } else {
                console.warn(`Opportunity with ID ${id} not found in the list.`);
            }
            return acc;
        }, []);

        // Ensure we only add valid opportunities
        if (newFilteredOpportunities.length > 0) {
            setSelectedOpportunities(prevOpportunities => [
                ...prevOpportunities.filter(o => o.roomId !== roomId),
                ...newFilteredOpportunities
            ]);
        } else {
            console.warn(`No valid opportunities found for room ${roomId}`);
        }
    };

    const convertPdfToImage = async (pdfUrl) => {
        const pdf = await pdfjsLib.getDocument(pdfUrl).promise;
        const jpegByteArrays = [];  // Store JPEG byte arrays for each page

        for (let pageNum = 1; pageNum <= pdf.numPages; pageNum++) {
            const page = await pdf.getPage(pageNum);
            const viewport = page.getViewport({ scale: 3 });

            // Create an off-screen canvas for rendering
            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d');
            canvas.width = viewport.width;
            canvas.height = viewport.height;

            // Render PDF page into the canvas
            await page.render({ canvasContext: context, viewport: viewport, renderInteractiveForms: false, useWebGL: true }).promise;

            // Convert the canvas image to Blob and then to ArrayBuffer
            const jpegBlob = await new Promise(resolve => canvas.toBlob(resolve, 'image/jpeg', 0.9));
            const jpegArrayBuffer = await jpegBlob.arrayBuffer();

            jpegByteArrays.push(jpegArrayBuffer);  // Store each page as a JPEG byte array
        }

        return jpegByteArrays;  // Returns JPEG byte arrays, ready for embedding
    };


    const addAttachmentToPdf = async (pdfDoc, attachment, helveticaFont, opportunityQuoteAmounts, pageWidth, pageHeight) => {
        const extension = attachment.attachmentPath.split('.').pop().toLowerCase();
        const backgroundColor = rgb(224 / 255, 212 / 255, 196 / 255);

        if (['jpg', 'jpeg', 'png'].includes(extension)) {
            try {
                const imgBytes = await fetch(getImageUrl(attachment.attachmentPath)).then(res => res.arrayBuffer());
                const img = extension === 'png' ? await pdfDoc.embedPng(imgBytes) : await pdfDoc.embedJpg(imgBytes);

                const isType3Or4 = attachment.attachmentTypeId === 3 || attachment.attachmentTypeId === 4;

                // Determine image dimensions while keeping the aspect ratio
                let imgWidth = img.width;
                let imgHeight = img.height;
                if (isType3Or4 && (imgWidth > pageWidth || imgHeight > pageHeight)) {
                    const widthScale = pageWidth / imgWidth;
                    const heightScale = pageHeight / imgHeight;
                    const scale = Math.min(widthScale, heightScale); // Scale to fit within page dimensions
                    imgWidth *= scale;
                    imgHeight *= scale;
                } else if (!isType3Or4) {
                    imgWidth = pageWidth;
                    imgHeight = pageHeight;
                }

                const xPosition = (pageWidth - imgWidth) / 2;
                const yPosition = (pageHeight - imgHeight) / 2;

                // Create a new page and add background color
                const page = pdfDoc.addPage([pageWidth, pageHeight]);
                page.drawRectangle({
                    x: 0,
                    y: 0,
                    width: pageWidth,
                    height: pageHeight,
                    color: backgroundColor,
                });

                // Draw the image on the page
                page.drawImage(img, {
                    x: xPosition,
                    y: yPosition,
                    width: imgWidth,
                    height: imgHeight,
                });
            } catch (error) {
                console.error(`Error embedding image ${attachment.attachmentPath}:`, error);
            }
        } else if (extension === 'pdf') {
            const jpegByteArrays = await convertPdfToImage(getImageUrl(attachment.attachmentPath));

            for (const jpegBytes of jpegByteArrays) {
                try {
                    const img = await pdfDoc.embedJpg(jpegBytes);

                    const isType3Or4 = attachment.attachmentTypeId === 3 || attachment.attachmentTypeId === 4;

                    // Determine image dimensions while keeping the aspect ratio
                    let imgWidth = img.width;
                    let imgHeight = img.height;
                    if (isType3Or4 && (imgWidth > pageWidth || imgHeight > pageHeight)) {
                        const widthScale = pageWidth / imgWidth;
                        const heightScale = pageHeight / imgHeight;
                        const scale = Math.min(widthScale, heightScale);
                        imgWidth *= scale;
                        imgHeight *= scale;
                    } else if (!isType3Or4) {
                        imgWidth = pageWidth;
                        imgHeight = pageHeight;
                    }

                    const xPosition = (pageWidth - imgWidth) / 2;
                    const yPosition = (pageHeight - imgHeight) / 2;

                    const page = pdfDoc.addPage([pageWidth, pageHeight]);
                    page.drawRectangle({
                        x: 0,
                        y: 0,
                        width: pageWidth,
                        height: pageHeight,
                        color: backgroundColor,
                    });

                    page.drawImage(img, {
                        x: xPosition,
                        y: yPosition,
                        width: imgWidth,
                        height: imgHeight,
                    });
                } catch (error) {
                    console.error("Error embedding PDF page image:", error);
                }
            }
        }
    };

    function truncateText(text, maxLength) {
        if (text.length > maxLength) {
            return `${text.substring(0, maxLength)}...`;
        }
        return text;
    }

    const onFinish = async (values) => {
        setLoading(true);
        try {
            const templatePath = values.language === 'ru'
                ? "/Ofertă-de-preț-ru.pdf"
                : "/Ofertă-de-preț-ro.pdf";

            const templateBytes = await fetch(templatePath).then(res => res.arrayBuffer());
            const pdfDoc = await PDFDocument.load(templateBytes);
            pdfDoc.registerFontkit(fontkit);
            const helveticaFontBytes = await fetch('/Roboto/Roboto-Regular.ttf').then(res => res.arrayBuffer());
            const helveticaFontBoldBytes = await fetch('/Roboto/Roboto-Bold.ttf').then(res => res.arrayBuffer());
            const helveticaFont = await pdfDoc.embedFont(helveticaFontBytes);
            const helveticaFontBold = await pdfDoc.embedFont(helveticaFontBoldBytes);

            const pageCount = pdfDoc.getPageCount();
            if (pageCount > 0) {
                pdfDoc.removePage(pageCount - 1);
            }

            const [originalPage] = pdfDoc.getPages();
            const { width, height } = originalPage.getSize();

            const opportunityQuoteAmounts = {};
            for (const quote of quoteList) {
                opportunityQuoteAmounts[quote.opportunityId] = quote.offerAmount;
            }

            for (const opportunity of selectedOpportunities) {
                if (!opportunity || !opportunity.id) {
                    console.warn("Invalid opportunity:", opportunity);
                    return;
                }

                const type3Attachments = attachmentList.filter(
                    (attachment) => attachment.opportunity?.id === opportunity.id && attachment.attachmentTypeId === 3
                );
                const type4Attachments = attachmentList.filter(
                    (attachment) => attachment.opportunity?.id === opportunity.id && attachment.attachmentTypeId === 4
                );

                const orderedAttachments = [];
                const maxLength = Math.max(type3Attachments.length, type4Attachments.length);
                for (let i = 0; i < maxLength; i++) {
                    if (i < type3Attachments.length) {
                        orderedAttachments.push(type3Attachments[i]);
                    }
                    if (i < type4Attachments.length) {
                        orderedAttachments.push(type4Attachments[i]);
                    }
                }

                for (const attachment of orderedAttachments) {
                    await addAttachmentToPdf(pdfDoc, attachment, helveticaFont, opportunityQuoteAmounts, width, height);
                }
            }

            const backgroundColor = rgb(224 / 255, 212 / 255, 196 / 255);
            const selectedQuotes = quoteList.filter(quote =>
                selectedOpportunities.some(opportunity => opportunity.id === quote.opportunityId)
            );

            const createTablePage = (quotes, showTotal = false) => {
                const pageWidth = width;
                const pageHeight = height;
                const backgroundColor = rgb(224 / 255, 212 / 255, 196 / 255);

                const cellPadding = 5;
                const fontSize = 12;
                const rowHeight = fontSize + 2 * cellPadding;

                let totalPageTotal = 0; // Grand total across all pages

                // Function to calculate text width
                const calculateTextWidth = (text, font, fontSize) => {
                    return font.widthOfTextAtSize(text, fontSize);
                };

                // Function to draw a single table on a page
                const drawTable = (page, quotes, startX, startY) => {
                    const quote = quotes[0];
                    const opportunity = selectedOpportunities.find(
                        (opp) => opp.id === quote.opportunityId
                    );

                    const opportunityName = opportunity?.opportunityName || "Unknown Opportunity";
                    const contactRoomName = opportunity?.contactRoom?.room?.roomName || "Unknown Room";

                    // Create header text
                    const headerText = `${contactRoomName}: ${opportunityName}`;

                    // Calculate dynamic table width based on header text length
                    const headerWidth = calculateTextWidth(headerText, helveticaFont, 14) + 2 * cellPadding;
                    const priceHeaderWidth =
                        calculateTextWidth("Preț per Unitate", helveticaFont, 12) + 2 * cellPadding;
                    let pageTotal = 0;

                    // Draw header title
                    page.drawText(headerText, {
                        x: startX,
                        y: startY - 30,
                        size: 14,
                        font: helveticaFontBold,
                        color: rgb(0, 0, 0),
                    });

                    startY -= 60;
                    const tableWidth = Math.max(headerWidth + priceHeaderWidth, pageWidth * 0.2);
                    const headers = ["Nume Produs", "Preț per Unitate"];

                    // Draw headers
                    headers.forEach((header, index) => {
                        const x = startX + index * (tableWidth / headers.length);
                        page.drawText(header, {
                            x: x + cellPadding,
                            y: startY - fontSize - cellPadding,
                            size: fontSize,
                            font: helveticaFont,
                            color: rgb(0, 0, 0),
                        });
                    });

                    page.drawLine({
                        start: { x: startX, y: startY - rowHeight },
                        end: { x: startX + tableWidth, y: startY - rowHeight },
                        thickness: 1,
                        color: rgb(0, 0, 0),
                    });

                    startY -= rowHeight;

                    // Draw table rows
                    quotes.forEach((quote) => {
                        quote.quoteProduct.forEach((product) => {
                            const productName = truncateText(product.product.productName, 20);
                            const pricePerUnit = `${(product.product.pricePerUnit * maximX).toFixed(2)}`;

                            page.drawText(productName, {
                                x: startX + cellPadding,
                                y: startY - fontSize - cellPadding,
                                size: fontSize,
                                font: helveticaFont,
                                color: rgb(0, 0, 0),
                            });
                            page.drawText(pricePerUnit, {
                                x: startX + (tableWidth / 2) + cellPadding,
                                y: startY - fontSize - cellPadding,
                                size: fontSize,
                                font: helveticaFont,
                                color: rgb(0, 0, 0),
                            });

                            page.drawLine({
                                start: { x: startX, y: startY },
                                end: { x: startX + tableWidth, y: startY },
                                thickness: 1,
                                color: rgb(0, 0, 0),
                            });

                            startY -= rowHeight;
                        });

                        const pageQuoteTotal =
                            quote.offerAmount - (quote.discount || 0) / eurExchangeRate;
                        pageTotal += pageQuoteTotal; // Add to page total
                        totalPageTotal += pageQuoteTotal; // Add to grand total

                        const totalLabel = "Total:";
                        const offerAmountValue = `${Math.ceil(pageQuoteTotal)} lei (${(
                            pageQuoteTotal / eurExchangeRate
                        ).toFixed(2)} €)`;

                        page.drawText(totalLabel, {
                            x: startX + cellPadding,
                            y: startY - 10,
                            size: 14,
                            font: helveticaFontBold,
                            color: rgb(0, 0, 0),
                        });

                        page.drawText(offerAmountValue, {
                            x: startX + (tableWidth / 2) + cellPadding,
                            y: startY - 10,
                            size: 14,
                            font: helveticaFontBold,
                            color: rgb(0, 0, 0),
                        });

                        page.drawLine({
                            start: { x: startX, y: startY - rowHeight },
                            end: { x: startX + tableWidth, y: startY - rowHeight },
                            thickness: 1,
                            color: rgb(0, 0, 0),
                        });

                        startY -= rowHeight;
                    });

                    return { pageTotal, startY };
                };

                // Process quotes in groups of 4
                for (let i = 0; i < quotes.length; i += 4) {
                    const page = pdfDoc.addPage([pageWidth, pageHeight]);
                    page.drawRectangle({
                        x: 0,
                        y: 0,
                        width: pageWidth,
                        height: pageHeight,
                        color: backgroundColor,
                    });

                    let startY = pageHeight - 100;
                    const pageQuotes = quotes.slice(i, i + 4);

                    pageQuotes.forEach((quote) => {
                        const { pageTotal, startY: updatedStartY } = drawTable(
                            page,
                            [quote],
                            30,
                            startY
                        );
                        startY = updatedStartY; // Update start Y for next table
                    });

                    // Add grand total to the last page if `showTotal` is true
                    if (showTotal && i + 4 >= quotes.length) {
                        const totalInEur = (totalPageTotal / eurExchangeRate).toFixed(2);
                        page.drawText(
                            `Sumă Totală: ${Math.ceil(totalPageTotal)} lei (${totalInEur} €)`,
                            {
                                x: 50,
                                y: 50,
                                size: 16,
                                font: helveticaFontBold,
                                color: rgb(0, 0, 0),
                            }
                        );
                    }
                }
            };

            createTablePage(selectedQuotes, true);

            const selectedContact = contactList.find(contact => contact.id === values.contactId);
            const pdfBytes = await pdfDoc.save();
            const blob = new Blob([pdfBytes], { type: 'application/pdf' });
            saveAs(blob, 'Oferta.pdf');

            const formData = new FormData();
            formData.append('contactId', values.contactId);
            formData.append('language', values.language);
            formData.append('offerFile', blob, `${selectedContact.firstName}_${selectedContact.lastName}_offer.pdf`);
            const resp = dispatch(addSingleOffer(formData));
            if (resp.payload.message === "success") {
                form.resetFields();
                if (createAs?.name) {
                    dispatch(createAs.singleLoadThunk(createAs.value));
                } else {
                    dispatch(loadAllOffer({}));
                }
                onClose();
            }
        } catch (error) {
            console.error("Error generating PDF:", error);
        } finally {
            setLoading(false);
        }
    };

    const onCancel = () => {
        form.resetFields();
        onClose();
    };

    const getRoomName = (roomId) => {
        const room = contactRooms.find(room => room.id === roomId);
        return room ? room.room.roomName : 'Unknown Room';
    };

    return (
        <div className="flex flex-col items-center mt-5">
            {(loading) ? (
                <div className="flex items-center justify-center h-screen">
                    <Spin size="large" />
                </div>
            ) : (
                <>
                    <Form
                        className="w-4/5"
                        colon={false}
                        layout="vertical"
                        form={form}
                        initialValues={createAs ? { [createAs?.name]: createAs?.value } : {}}
                        onFinish={onFinish}
                    >
                        <Form.Item
                            name="contactId"
                            label={TEXTS.LABELS.CONTACT}
                            rules={[{ required: true, message: `${TEXTS.MESSAGES.REQUIRED_MESSAGE}` }]}
                        >
                            <Select
                                loading={contactLoading}
                                placeholder={`${TEXTS.PLACEHOLDERS.CONTACT_PLACEHOLDER}`}
                                showSearch
                                allowClear
                                style={{ width: "100%" }}
                                onChange={handleContactChange}
                                disabled={createAs?.name === "contactId"}
                            >
                                {contactList.map(contact => (
                                    <Select.Option key={contact.id} value={contact.id}>
                                        {contact.firstName} {contact.lastName}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>

                        <Form.Item
                            name="contactRoomIds"
                            label={TEXTS.LABELS.ROOM}
                            rules={[{ required: true, message: `${TEXTS.MESSAGES.REQUIRED_MESSAGE}` }]}
                        >
                            <Select
                                loading={contactRoomLoading}
                                colon={false}
                                allowClear
                                style={{ width: "100%" }}
                                placeholder={`${TEXTS.TEXT.SELECT} ${TEXTS.LABELS.ROOM}`}
                                mode="multiple"
                                onChange={handleContactRoomChange}
                            >
                                {contactRooms.map(room => (
                                    <Select.Option key={room.id} value={room.id}>
                                        {room?.room?.roomName}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>

                        {/* Map through each selected contactRoom to render its opportunities */}
                        {selectedContactRooms?.map(roomId => (
                            <Form.Item
                                key={`opportunity-${roomId}`}
                                name={`opportunityIds-${roomId}`} // Unique field name for each room
                                label={`${TEXTS.LABELS.OPPORTUNITIES} pentru ${getRoomName(roomId)}`}
                                rules={[{ required: true, message: `${TEXTS.MESSAGES.REQUIRED_MESSAGE}` }]}
                            >
                                <Select
                                    loading={opportunityLoading}
                                    placeholder={`${TEXTS.PLACEHOLDERS.OPPORTUNITY_PLACEHOLDER}`}
                                    mode="multiple"
                                    onChange={value => handleOpportunityChange(value, roomId)}
                                >
                                    {filteredOpportunities[roomId]?.map(opportunity => (
                                        <Select.Option key={opportunity.id} value={opportunity.id}>
                                            {opportunity?.opportunityName}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        ))}

                        {Object.keys(warnings).length > 0 && (
                            <div className="w-full mt-4">
                                {Object.keys(warnings).map(opportunityId => (
                                    <Alert
                                        key={opportunityId}
                                        message={warnings[opportunityId]}
                                        type="warning"
                                        showIcon
                                        className="mb-2"
                                    />
                                ))}
                            </div>
                        )}

                        <Form.Item
                            name="language"
                            label="Selectează Limba"
                            rules={[{ required: true, message: `${TEXTS.MESSAGES.REQUIRED_MESSAGE}` }]}
                        >
                            <Radio.Group>
                                <Radio value="ro">RO</Radio>
                                <Radio value="ru">RU</Radio>
                            </Radio.Group>
                        </Form.Item>

                        <Form.Item>
                            <Button type="primary" htmlType="submit">
                                Crează PDF
                            </Button>
                            <Button type="default" onClick={onCancel} style={{ marginLeft: "8px" }}>
                                {TEXTS.BUTTON_TEXT.CLOSE_BTN}
                            </Button>
                        </Form.Item>
                    </Form>
                </>
            )}
        </div>
    );

}
