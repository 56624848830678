import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Board from "react-trello";
import { loadAllContact, updateContactStage } from "../../../redux/rtk/features/crm/contact/contactSlice";
import { Button, Modal, Input, DatePicker, message, Select } from "antd";
import CreateContactForm from "../Contacts/CreateContactForm";
import { useNavigate } from "react-router-dom";
import getCurrentUser from "../../user/getCurrentUserapi";
import "../../../App.css";
import moment from "moment";
import { loadAllFailureCauses } from "../../../redux/rtk/features/crm/causeOfFailure/causeOfFailureSlice";
import { TEXTS } from "../../../constants";
import { loadAllContract } from "../../../redux/rtk/features/crm/contract/contractSlice";
import getRoleFromToken from "../../../utils/getRoleFromToken";
import { loadAllOpportunity } from "../../../redux/rtk/features/crm/opportunity/opportunitySlice";
import { loadAllContactRooms } from "../../../redux/rtk/features/crm/contactRoom/contactRoomSlice";

const { Option } = Select;

const convertToUTC3 = (date) => {
    return moment(date).utc().add(3, 'hours').toISOString();
}

export default function KanbanBoard() {

    const userRole = getRoleFromToken();

    // Roles that have access to all sections
    const allAccessRoles = ["Fondator", "Cofondator", "admin", "Administrator", "Manager Operațional"];


    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { list: contactList, loading: contactLoading } = useSelector((state) => state.contact);
    const { list: contractList, loading: contractLoading } = useSelector((state) => state.contract);
    const { list: opportunityList, loading: opportunityLoading } = useSelector((state) => state.opportunity);
    const { list: contactRooms, loading: contactRoomLoading } = useSelector((state) => state.contactRoom);
    const { list: failureCauseList, loading: failureLoading } = useSelector((state) => state.failureCause);
    const [boardData, setBoardData] = useState({ lanes: [] });
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isSpecialModalOpen, setIsSpecialModalOpen] = useState(false);
    const [isDateModalOpen, setIsDateModalOpen] = useState(false);
    const [selectedCard, setSelectedCard] = useState(null);
    const [failureCause, setFailureCause] = useState(null);
    const [description, setDescription] = useState("");
    const [contactCloseDate, setContactCloseDate] = useState(null);
    const [currentUser, setCurrentUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [draggedCardId, setDraggedCardId] = useState(null);
    const [newLaneName, setNewLaneName] = useState(null);
    const [maxAllowedDate, setMaxAllowedDate] = useState(null);
    const [minAllowedDate, setMinAllowedDate] = useState(null);
    const [selectedLanes, setSelectedLanes] = useState([]);

    useEffect(() => {
        dispatch(loadAllContact({ status: true }));
        dispatch(loadAllOpportunity());
        dispatch(loadAllContract());
        dispatch(loadAllFailureCauses());
        dispatch(loadAllContactRooms());
    }, [dispatch]);

    useEffect(() => {
        (async () => {
            try {
                const user = await getCurrentUser();
                setCurrentUser(user);
            } catch (error) {
                console.error("Error fetching current user:", error.message);
            } finally {
                setLoading(false);
            }
        })();
    }, []);

    useEffect(() => {
        if (contactLoading && contactRoomLoading && opportunityLoading) return;
        setBoardData(prepareBoardData());
    }, [contactList, currentUser, selectedLanes]);

    function generateContactID(contact) {
        const departmentId = contact.contactOwner?.department?.id < 10
            ? `0${contact?.contactOwner?.department?.id}`
            : contact?.contactOwner?.department?.id;

        const contactId = contact.id < 10
            ? `0${contact.id}`
            : contact.id;
        const createdAt = new Date(contact.createdAt);
        const month = (createdAt.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-based, so add 1
        const year = createdAt.getFullYear().toString().slice(-2); // Get last two digits of the year

        return `${departmentId ? departmentId : '0'}/${contactId}/${month}/${year}`;
    }


    const prepareBoardData = () => {
        const lanes = [
            { id: 'lead', title: 'Lead', label: '', cards: [] },
            { id: 'apelat', title: 'Apelat', label: '', cards: [] },
            { id: 'masurare', title: 'Măsurare', label: '', cards: [] },
            { id: 'proiectare', title: 'Proiectare', label: '', cards: [] },
            { id: 'calcule', title: 'Calcule', label: '', cards: [] },
            { id: 'prezentare', title: 'Prezentare', label: '', cards: [] },
            { id: 'contractat', title: 'Contractat', label: '', cards: [] },
            { id: 'predat', title: 'Predat Producere', label: '', cards: [] },
            { id: 'concretizari', title: 'Concretizări', label: '', cards: [] },
        ];

        if (currentUser && currentUser.department.id === 1) {
            lanes.push({ id: 'esuat', title: 'Eșuat', label: '', cards: [], style: { backgroundColor: '#f8d7da' } });
        }

        const laneCounts = {
            lead: 0,
            apelat: 0,
            masurare: 0,
            proiectare: 0,
            calcule: 0,
            prezentare: 0,
            contractat: 0,
            predat: 0,
            concretizari: 0,
            esuat: 0,
        };


        contactList.forEach(contact => {
            if (contact.contactStage.contactStageName === 'Predat Producere') {
                return;
            }

            const generatedId = generateContactID(contact);
            const updatedAt = new Date(contact.updatedAt).toLocaleString('ro-RO');
            const card = {
                id: contact.id,
                title: `ID:${generatedId}\n${contact.firstName} ${contact.lastName}`,
                description: `${updatedAt}\nTel: ${contact.phone}`,
                style: {},
            };

            const contactCloseDate = new Date(contact.contactCloseDate);
            const now = new Date();
            // Determine the time limits for "Eșuat" stage based on the user's role
            const oneDay = new Date(contactCloseDate.getTime() + 24 * 60 * 60 * 1000); // 1 day ago
            const thirtyOneDays = new Date(contactCloseDate.getTime() + 31 * 24 * 60 * 60 * 1000); // 31 days ago

            const timeDiff = contactCloseDate - now;
            const hoursDiff = timeDiff / (1000 * 3600); // Difference in hours

            if (hoursDiff <= 1) {
                card.style = { backgroundColor: '#f8d7da' }; // Highlight with a red shade if within 1 hour
            }
            const visibilityLimit = allAccessRoles.includes(userRole) ? thirtyOneDays : oneDay;
            if (contact.contactStage.contactStageName === 'Eșuat') {
                if (now > visibilityLimit) {
                    return; // Skip this contact based on the time limit
                }
            }
            const lane = lanes.find(lane => lane.title.toLowerCase() === contact.contactStage.contactStageName.toLowerCase());
            if (lane) {
                // Add card only if the lane is not "Eșuat" or it contains less than 5 cards
                if (lane.id !== 'esuat' || lane.cards.length < 5) {
                    lane.cards.push(card);
                    laneCounts[lane.id] += 1;
                }
            }
        });

        lanes.forEach(lane => {
            lane.label = `${laneCounts[lane.id]}`;
        });

        const filteredLanes = selectedLanes.length > 0
            ? lanes.filter(lane => selectedLanes.includes(lane.id))
            : lanes;

        return { lanes: filteredLanes };
    };


    const handleDragEnd = async (cardId, sourceLaneId, targetLaneId) => {

        if (sourceLaneId === targetLaneId) {
            message.warning('Nu e posibil de mutat clientul în aceeași etapa în care el deja este amplasat');
            return false;
        }

        const laneIdToStageNameMap = {
            lead: 'Lead',
            apelat: 'Apelat',
            masurare: 'Măsurare',
            proiectare: 'Proiectare',
            calcule: 'Calcule',
            prezentare: 'Prezentare',
            contractat: 'Contractat',
            predat: 'Predat Producere',
            concretizari: 'Concretizări',
            esuat: 'Eșuat',
        };
        const contact = contactList.find(contact => contact.id === cardId);
        if (contact) {
            const newStage = laneIdToStageNameMap[targetLaneId];
            if (newStage === 'Prezentare') {
                const hasOpportunities = opportunityList.some(opportunity => opportunity.contact.id === contact.id);
                const hasContactRooms = contactRooms.some(room => room.contactId === contact.id);

                if (!hasOpportunities) {
                    message.error('Contactul nu are proiecte asociate. Mutarea nu este permisă.');
                    await dispatch(loadAllContact({ status: true }));
                    await dispatch(loadAllOpportunity());
                    return; // Prevent moving to "Prezentare"
                }

                if (!hasContactRooms) {
                    message.error('Contactul nu are camere asociate. Mutarea nu este permisă.');
                    await dispatch(loadAllContact({ status: true }));
                    await dispatch(loadAllOpportunity());
                    return; // Prevent moving to "Prezentare"
                }
            }

            if ((contact.contractApproval === 'PENDING' || contact.contract === null) && newStage === 'Prezentare') {
                message.warning('Clientul dat așteapta sa fie aprobat de catre Tehnician Calcule');
                await dispatch(loadAllContact({ status: true }));
                return;
            }
            // Check if the new stage is "Predat Producere" and if there    is no contract associated with the contact
            const associatedContract = contractList.find(contract => contract.contactId === contact.id);
            if (newStage === 'Predat Producere' && !associatedContract) {
                message.error('Contactul dat nu are un contract asociat, mutarea nu este permisă.');
                await dispatch(loadAllContact({ status: true }));
                return; // Prevent moving to "Predat Producere"
            }
            const now = new Date();
            let maxAllowedDate = new Date(now.getTime() + 30 * 24 * 60 * 60 * 1000); // Default to 30 days

            // Set specific deadlines based on the stage
            switch (newStage) {
                case 'Lead':
                    maxAllowedDate = new Date(now.getTime() + 3 * 60 * 60 * 1000); // 3 hours
                    break;
                case 'Apelat':
                case 'Calcule':
                case 'Predat Producere':
                    maxAllowedDate = new Date(now.getTime() + 2 * 24 * 60 * 60 * 1000); // 1 day
                    break;
                case 'Măsurare':
                case 'Prezentare':
                    maxAllowedDate = new Date(now.getTime() + 6 * 24 * 60 * 60 * 1000); // 5 days
                    break;
                case 'Proiectare':
                case 'Concretizări':
                    maxAllowedDate = new Date(now.getTime() + 4 * 24 * 60 * 60 * 1000); // 3 days
                    break;
                case 'Contractat':
                    maxAllowedDate = new Date(now.getTime() + 31 * 24 * 60 * 60 * 1000); // 30 days
                    break;
                default:
                    maxAllowedDate = new Date(now.getTime() + 30 * 24 * 60 * 60 * 1000); // Default to 30 days
            }

            const minAllowedDate = new Date(now.getTime() - 24 * 60 * 60 * 1000); // 1 day ago

            setMaxAllowedDate(maxAllowedDate);
            setMinAllowedDate(minAllowedDate);
            setDraggedCardId(contact.id);
            if (newStage === 'Eșuat') {
                setSelectedCard(contact);
                setIsSpecialModalOpen(true);
            } else {
                setContactCloseDate(null); // Reset the contact close date
                setNewLaneName(newStage);
                setIsDateModalOpen(true);
            }
        }
    };

    const handleDateModalOk = async () => {
        if (draggedCardId && contactCloseDate && newLaneName) {
            const selectedDate = new Date(contactCloseDate);

            if (newLaneName === 'Prezentare') {
                // Check if the selected date is within 2 hours of any other 'Prezentare' contact's close date
                const twoHoursBefore = new Date(selectedDate.getTime() - 2 * 60 * 60 * 1000);
                const twoHoursAfter = new Date(selectedDate.getTime() + 2 * 60 * 60 * 1000);

                const conflictingContact = contactList.find(contact => {
                    const otherCloseDate = new Date(contact.contactCloseDate);
                    return (
                        contact.contactStage.contactStageName === 'Prezentare' &&
                        otherCloseDate <= twoHoursAfter &&
                        otherCloseDate >= twoHoursBefore &&
                        contact.id !== draggedCardId // Exclude the current contact
                    );
                });

                if (conflictingContact) {
                    message.error(`Data selectată este prea aproape de data prezentării altui client (ID: ${conflictingContact.id}). Vă rugăm să alegeți altă dată.`);
                    return;
                }
            }

            // Proceed with updating the contact stage and close date
            const contactCloseDateUTC3 = convertToUTC3(selectedDate);
            try {
                await dispatch(updateContactStage({
                    id: draggedCardId,
                    values: {
                        contactStageName: newLaneName,
                        contactCloseDate: contactCloseDateUTC3,
                    }
                }));
                dispatch(loadAllContact({ status: true }));
                setIsDateModalOpen(false);
                setDraggedCardId(null);
                setNewLaneName(null);
                setContactCloseDate(null);
            } catch (error) {
                console.error('Error updating contact stage:', error);
                message.error('Error updating contact.');
            }
        } else {
            if (!draggedCardId) {
                message.warning('Card ID is missing.');
            }
            if (!contactCloseDate) {
                message.warning('Date and time are missing.');
            }
            if (!newLaneName) {
                message.warning('Stage name is missing.');
            }
        }
    };



    const handleDateModalCancel = () => {
        setIsDateModalOpen(false);
        setDraggedCardId(null);
        setNewLaneName(null);
        setContactCloseDate(null);
        dispatch(loadAllContact({ status: true }));
    };

    const handleSpecialModalOk = async () => {
        if (selectedCard) {
            try {
                await dispatch(updateContactStage({ id: selectedCard.id, values: { contactStageName: 'Eșuat', description, failureCauseId: failureCause } }));
                dispatch(loadAllContact({ status: true }));
                setIsSpecialModalOpen(false);
                setSelectedCard(null);
                setDescription("");
                setFailureCause("");
            } catch (error) {
                message.error('Eroare la actualizarea contactului.');
            }
        }
    };

    const handleSpecialModalCancel = () => {
        setIsSpecialModalOpen(false);
        setSelectedCard(null);
        setDescription("");
        setFailureCause("");
        dispatch(loadAllContact({ status: true }));
    };

    const showModal = () => {
        setIsModalOpen(true);
    };

    const cancelModal = () => {
        setIsModalOpen(false);
        dispatch(loadAllContact({ status: true }));
    };

    const customLaneHeader = ({ title, label, id }) => {
        return (
            <div>
                <div style={{ fontWeight: 'bold' }}>{title}</div>
                <div>{label}</div>
                {id === 'lead' && (
                    <Button className="w-full" type="primary" style={{ marginBottom: '10px', marginTop: '5px' }} onClick={showModal}>
                        Adaugă Client Nou
                    </Button>
                )}
            </div>
        );
    };

    const components = {
        LaneHeader: customLaneHeader,
    };

    const onCardClick = (cardId) => {
        navigate(`/admin/contact/${cardId}`);
    };

    const handleFilterChange = (selectedLanes) => {
        setSelectedLanes(selectedLanes);
    }

    const handleFailureCause = (value) => {
        setFailureCause(value);
    }

    if (contactLoading || loading) return <div>Loading...</div>;

    return (
        <>
            <Select
                mode="multiple"
                placeholder='Selectați Etapele'
                style={{ width: '100%' }}
                onChange={handleFilterChange}
            >
                <Option value="lead">Lead</Option>
                <Option value="apelat">Apelat</Option>
                <Option value="masurare">Măsurare</Option>
                <Option value="proiectare">Proiectare</Option>
                <Option value="calcule">Calcule</Option>
                <Option value="prezentare">Prezentare</Option>
                <Option value="contractat">Contractat</Option>
                <Option value="predat">Predat Producere</Option>
                <Option value="concretizari">Concretizări</Option>
                {currentUser && currentUser.department.id === 1 && <Option value="esuat">Eșuat</Option>}
            </Select>
            <Board
                data={boardData}
                handleDragEnd={handleDragEnd}
                components={components}
                onCardClick={onCardClick}
                style={{ backgroundColor: 'aliceblue' }}
            />

            <Modal
                title="Selectează Deadline"
                visible={isDateModalOpen}
                onOk={handleDateModalOk}
                onCancel={handleDateModalCancel}
                okText="Salvează"
                cancelText="Anulează"
            >
                <DatePicker
                    showTime
                    value={contactCloseDate ? moment(contactCloseDate) : null}
                    onChange={(date) => setContactCloseDate(date ? date.toDate() : null)}
                    style={{ width: '100%' }}
                    disabledDate={(date) => date && (date < moment(minAllowedDate) || date > moment(maxAllowedDate))}
                    disabledTime={() => ({
                        disabledHours: () => {
                            const hours = [];
                            for (let i = 0; i < 24; i++) {
                                const date = new Date();
                                date.setHours(i);
                                if (date > maxAllowedDate) hours.push(i);
                            }
                            return hours;
                        },
                        disabledMinutes: () => {
                            const minutes = [];
                            if (contactCloseDate) {
                                const date = new Date(contactCloseDate);
                                for (let i = 0; i < 60; i++) {
                                    date.setMinutes(i);
                                    if (date > maxAllowedDate) minutes.push(i);
                                }
                            }
                            return minutes;
                        },
                        disabledSeconds: () => [],
                    })}
                    format="YYYY-MM-DD HH:mm:ss"
                />
                {contactCloseDate && maxAllowedDate && contactCloseDate > maxAllowedDate && (
                    <div style={{ color: 'red', marginTop: '10px' }}>
                        Data și ora selectate depășesc termenul limită permis de {moment(maxAllowedDate).format('YYYY-MM-DD HH:mm:ss')}.
                    </div>
                )}
            </Modal>

            <Modal
                title="Adaugă Detalii"
                visible={isSpecialModalOpen}
                onOk={handleSpecialModalOk}
                onCancel={handleSpecialModalCancel}
                okText="Salvează"
                cancelText="Anulează"
                okButtonProps={{ disabled: !description || !failureCause }}
            >
                <Input.TextArea
                    rows={4}
                    value={description}
                    required
                    onChange={(e) => setDescription(e.target.value)}
                    placeholder="Introdu detalii aici..."
                />
                {!description && <p style={{ color: 'red' }}>Acest câmp este obligatoriu.</p>}
                <span><b>{`${TEXTS.TEXT.SELECT} ${TEXTS.LABELS.CAUSE_OF_FAILURE}`}</b></span>
                <Select
                    style={{ width: "100%" }}
                    loading={failureLoading}
                    allowClear
                    showSearch
                    value={failureCause}
                    onChange={handleFailureCause}
                    placeholder={`${TEXTS.TEXT.SELECT} ${TEXTS.LABELS.CAUSE_OF_FAILURE}`}
                >
                    {failureCauseList?.map((item) => (
                        <Select.Option key={item.id} value={item.id}>
                            {item?.cause}
                        </Select.Option>
                    ))}
                </Select>
                {!failureCause && <p style={{ color: 'red' }}>Selectarea unei cauze este obligatorie.</p>}
            </Modal>

            <Modal
                title="Adaugă Client Nou"
                visible={isModalOpen}
                footer={null}
                onCancel={cancelModal}
                width={800}
            >
                <CreateContactForm onClose={cancelModal} />
            </Modal>
        </>
    );
}
