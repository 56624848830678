import { Form, Input, Select, Button } from "antd";
import { TEXTS } from "../../../../constants";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { loadAllContactPaginated } from "../../../../redux/rtk/features/crm/contact/contactSlice";
import { PDFDocument, StandardFonts } from 'pdf-lib';
import { saveAs } from 'file-saver';
import { addSingleContract, loadAllContract } from "../../../../redux/rtk/features/crm/contract/contractSlice";
import { loadAllOpportunity } from "../../../../redux/rtk/features/crm/opportunity/opportunitySlice";
import fontkit from '@pdf-lib/fontkit';
import { loadAllContactRooms } from "../../../../redux/rtk/features/crm/contactRoom/contactRoomSlice";

export default function CreateContractForm({ onClose, createAs }) {
    const [form] = Form.useForm();
    const [filteredOpportunityList, setFilteredOpportunityList] = useState([]);
    const [totalAmount, setTotalAmount] = useState(0);
    const [filteredContactRooms, setFilteredContactRooms] = useState([]);
    const [filteredOpportunities, setFilteredOpportunities] = useState({});
    const [selectedContactRooms, setSelectedContactRooms] = useState([]);

    const { list: contactList, loading: contactLoading } = useSelector((state => state.contact));
    const { list: contractList, loading: contractLoading } = useSelector((state => state.contract));
    const { list: contactRoom, loading: contactRoomLoading } = useSelector((state) => state.contactRoom);
    const { list: opportunityList, loading: opportunityLoading } = useSelector((state => state.opportunity));
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(loadAllContactPaginated({ status: true, count: 999 }));
        dispatch(loadAllOpportunity());
        dispatch(loadAllContract());
        dispatch(loadAllContactRooms());
    }, [dispatch]);

    useEffect(() => {
        if (contactRoomLoading) return;

        if (createAs?.name === "contactId" && createAs?.value) {
            const roomsForContact = contactRoom.filter((room) => room.contactId === createAs.value);
            setFilteredContactRooms(roomsForContact);
            form.setFieldsValue({ contactId: createAs.value }); // Set the selected contact in the form
        }
    }, [contactRoomLoading, createAs, contactRoom, form]);

    useEffect(() => {
        if (contactLoading) return;

        if (createAs?.name === "contactId" && createAs?.value) {
            const initialContactId = createAs?.value;
            const filteredOpportunities = opportunityList.filter(opportunity => opportunity.contactId === initialContactId);
            setFilteredOpportunityList(filteredOpportunities);
            form.setFieldsValue({ contactId: initialContactId }); // Set the selected contact in the form
        }
    }, [setFilteredOpportunityList])

    const onContactChange = (contactId) => {
        // Filter contact rooms for the selected contact
        const roomsForContact = contactRoom.filter((room) => room.contactId === contactId);
        setFilteredContactRooms(roomsForContact);

        // Reset state for opportunities and totalAmount
        setFilteredOpportunityList([]);
        setTotalAmount(0);
        form.setFieldsValue({ contactRoomIds: [], opportunityIds: [], price: 0 });
    };

    const onContactRoomChange = (contactRoomIds) => {
        // Filter opportunities based on selected contact rooms
        const opportunities = opportunityList.filter((opp) =>
            contactRoomIds.some((roomId) => opp.contactRoomId === roomId)
        );
        setFilteredOpportunityList(opportunities);

        // Update selected contact rooms
        setSelectedContactRooms(contactRoomIds);

        // Reset total amount and selected opportunities
        setTotalAmount(0);
        form.setFieldsValue({ opportunityIds: [], price: 0 });
    };

    const onOpportunityChange = (opportunityIds) => {
        // Update the total amount based on selected opportunities
        const selectedOpportunities = opportunityList.filter((opp) => opportunityIds.includes(opp.id));
        const total = selectedOpportunities.reduce((sum, opp) => sum + opp.offerAmount, 0);
        setTotalAmount(total);
        form.setFieldsValue({ price: total });
    };

    const generatePDF = async (contact, price, financialGarancy, beneficiar, contractId, language) => {
        const url = language === 'ru'
            ? '/Default_Contract_RU.pdf'
            : '/Default_Contract_RO.pdf';
        const existingPdfBytes = await fetch(url).then(res => res.arrayBuffer());
        const pdfDoc = await PDFDocument.load(existingPdfBytes);
        pdfDoc.registerFontkit(fontkit);
        const helveticaFontBytes = await fetch('/Roboto/Roboto-Regular.ttf').then(res => res.arrayBuffer());
        const helveticaFont = await pdfDoc.embedFont(helveticaFontBytes);

        // Get the first page of the document
        const pages = pdfDoc.getPages();
        const firstPage = pages[0];
        const secondPage = pages[1];
        const lastPage = pages[9];
        const currentDate = new Date().toLocaleDateString('ro-RO', {
            day: '2-digit',
            month: '2-digit'
        }).replace(',', '');
        if (language === 'ro') {
            firstPage.drawText(`${contractId}`, {
                x: 348,
                y: 610,
                size: 12,
                font: helveticaFont,
            });
            firstPage.drawText(`${currentDate}`, {
                x: 180,
                y: 553,
                size: 12,
                font: helveticaFont,
            });
            // Draw the full name on the first page
            firstPage.drawText(`${contact.lastName} ${contact.firstName}`, {
                x: 95,
                y: 463,
                size: 12,
                font: helveticaFont,
            });

            firstPage.drawText(`${contact.idnp}`, {
                x: 395,
                y: 463,
                size: 12,
                font: helveticaFont,
            });
            firstPage.drawText(`${contact.permanentAddress}`, {
                x: 315,
                y: 448,
                size: 12,
                font: helveticaFont,
            });
            firstPage.drawText(`${contact.permanentAddress}`, {
                x: 190,
                y: 416,
                size: 12,
                font: helveticaFont,
            });
            firstPage.drawText(`${contact.phone}`, {
                x: 226,
                y: 432,
                size: 12,
                font: helveticaFont,
            });
            secondPage.drawText(`${price}`, {
                x: 176,
                y: 389,
                size: 12,
                font: helveticaFont,
            });
            secondPage.drawText(`${financialGarancy}`, {
                x: 171,
                y: 297,
                size: 12,
                font: helveticaFont,
            });
            secondPage.drawText(`${beneficiar}`, {
                x: 339,
                y: 202,
                size: 12,
                font: helveticaFont,
            });
            secondPage.drawText(`${100 - financialGarancy - beneficiar}`, {
                x: 192,
                y: 171,
                size: 12,
                font: helveticaFont,
            });
            lastPage.drawText(`${contact.lastName} ${contact.firstName}`, {
                x: 425,
                y: 633,
                size: 12,
                font: helveticaFont,
            });
            lastPage.drawText(`${contact.idnp}`, {
                x: 410,
                y: 603,
                size: 12,
                font: helveticaFont,
            });
            lastPage.drawText(`${contact.permanentAddress}`, {
                x: 352,
                y: 573,
                size: 12,
                font: helveticaFont,
            });
            lastPage.drawText(`${contact.email}`, {
                x: 348,
                y: 543,
                size: 12,
                font: helveticaFont,
            });
            lastPage.drawText(`${contact.phone}`, {
                x: 355,
                y: 513,
                size: 12,
                font: helveticaFont,
            });
        } else {
            firstPage.drawText(`${contractId}`, {
                x: 348,
                y: 662,
                size: 12,
                font: helveticaFont,
            });
            firstPage.drawText(`${currentDate}`, {
                x: 220,
                y: 621,
                size: 12,
                font: helveticaFont,
            });
            // Draw the full name on the first page
            firstPage.drawText(`${contact.lastName} ${contact.firstName}`, {
                x: 178,
                y: 538,
                size: 12,
                font: helveticaFont,
            });

            firstPage.drawText(`${contact.idnp}`, {
                x: 255,
                y: 508,
                size: 12,
                font: helveticaFont,
            });
            firstPage.drawText(`${contact.permanentAddress}`, {
                x: 215,
                y: 495,
                size: 12,
                font: helveticaFont,
            });
            firstPage.drawText(`${contact.permanentAddress}`, {
                x: 336,
                y: 480,
                size: 12,
                font: helveticaFont,
            });
            firstPage.drawText(`${contact.phone}`, {
                x: 136,
                y: 480,
                size: 12,
                font: helveticaFont,
            });
            secondPage.drawText(`${price}`, {
                x: 376,
                y: 451,
                size: 12,
                font: helveticaFont,
            });
            secondPage.drawText(`${financialGarancy}`, {
                x: 221,
                y: 355,
                size: 12,
                font: helveticaFont,
            });
            secondPage.drawText(`${beneficiar}`, {
                x: 314,
                y: 258,
                size: 12,
                font: helveticaFont,
            });
            secondPage.drawText(`${100 - financialGarancy - beneficiar}`, {
                x: 242,
                y: 216,
                size: 12,
                font: helveticaFont,
            });
            lastPage.drawText(`${contact.lastName} ${contact.firstName}`, {
                x: 385,
                y: 423,
                size: 12,
                font: helveticaFont,
            });
            lastPage.drawText(`${contact.idnp}`, {
                x: 433,
                y: 393,
                size: 12,
                font: helveticaFont,
            });
            lastPage.drawText(`${contact.permanentAddress}`, {
                x: 367,
                y: 365,
                size: 12,
                font: helveticaFont,
            });
            lastPage.drawText(`${contact.email}`, {
                x: 430,
                y: 340,
                size: 12,
                font: helveticaFont,
            });
            lastPage.drawText(`${contact.phone}`, {
                x: 376,
                y: 310,
                size: 12,
                font: helveticaFont,
            });
        }
        // Serialize the PDFDocument to bytes (a Uint8Array)
        const pdfBytes = await pdfDoc.save();

        // Download the generated PDF
        const blob = new Blob([pdfBytes], { type: 'application/pdf' });
        saveAs(blob, `${contact.firstName}_${contact.lastName}_contract_${language}.pdf`);
        return blob;
    }

    const onFinish = async (values) => {
        const selectedContact = contactList.find(contact => contact.id === values.contactId);
        if (selectedContact) {
            // Validate price format
            const priceMatch = values.price?.match(/^(\d+)\s*\(([\wăîșțâ\s]+)\)$/);

            if (!priceMatch) {
                // Show error if the format is incorrect
                form.setFields([
                    {
                        name: 'price',
                        errors: ['Prețul trebuie să fie în formatul: 5000 (cinci mii)'],
                    },
                ]);
                return; // Stop further execution if format is invalid
            }
            const language = values.language || 'ro';
            const price = parseInt(priceMatch[1], 10); // Extract the numeric value
            const newContractId = contractList.length === 0 ? 1 : contractList[contractList.length - 1].id + 1;
            const pdfBlob = await generatePDF(selectedContact, values.price, values.financialGarancy, values.beneficiar, newContractId, language);
            const formData = new FormData();
            formData.append('contactId', values.contactId);
            formData.append('opportunityIds', JSON.stringify(values.opportunityIds));
            formData.append('sum', price);
            formData.append('avansPercent', values.financialGarancy);
            formData.append('language', language)
            formData.append('contractFile', pdfBlob, `${selectedContact.firstName}_${selectedContact.lastName}_contract.pdf`);
            const resp = await dispatch(addSingleContract(formData));
            if (resp.payload.message === "success") {
                form.resetFields();
                if (createAs?.name) {
                    dispatch(createAs.singleLoadThunk(createAs.value));
                } else {
                    dispatch(loadAllContract());
                }
                onClose();
            }
        }
    };

    const onCancel = () => {
        form.resetFields();
        onClose();
    }

    return (
        <div className="flex justify-center mt-5">
            <Form
                className="w-4/5"
                colon={false}
                layout="vertical"
                form={form}
                initialValues={
                    createAs
                        ? {
                            [createAs?.name]: createAs?.value,
                        }
                        : {}
                }
                onFinish={onFinish}
            >
                <div className="w-1/2">
                    <Form.Item
                        label="Nume Client"
                        name="contactId"
                        rules={[{ required: true, message: `${TEXTS.TEXT.REQUIRED_FIELD}` }]}
                    >
                        <Select
                            allowClear
                            showSearch
                            placeholder={`${TEXTS.PLACEHOLDERS.CONTACT_PLACEHOLDER}`}
                            onChange={onContactChange}
                            disabled={!!(createAs?.name === "contactId")}
                        >
                            {contactList.map((contact) => (
                                <Select.Option key={contact.id} value={contact.id}>
                                    {contact.firstName} {contact.lastName}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="Camere Contact"
                        name="contactRoomIds"
                        rules={[{ required: true, message: `${TEXTS.TEXT.REQUIRED_FIELD}` }]}
                    >
                        <Select
                            mode="multiple"
                            allowClear
                            placeholder="Selectați camerele contactului"
                            onChange={onContactRoomChange}
                        >
                            {filteredContactRooms.map((room) => (
                                <Select.Option key={room.id} value={room.id}>
                                    {room.room.roomName}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="Proiecte"
                        name="opportunityIds"
                        rules={[{ required: true, message: `${TEXTS.TEXT.REQUIRED_FIELD}` }]}
                    >
                        <Select
                            mode="multiple"
                            allowClear
                            placeholder={`${TEXTS.PLACEHOLDERS.OPPORTUNITY_PLACEHOLDER}`}
                            onChange={onOpportunityChange}
                            loading={opportunityLoading}
                        >
                            {filteredOpportunityList.map((opp) => (
                                <Select.Option key={opp.id} value={opp.id}>
                                    {opp.opportunityName}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </div>
                <div className="w-1/2">
                    <Form.Item
                        style={{ width: "100%" }}
                        label="Retribuție( preț )"
                        name={"price"}
                        rules={[{ required: true, message: 'Introdu prețul' }]}
                    >
                        <Input placeholder="5000 (cinci mii)" />
                    </Form.Item>
                </div>
                <div className="flex justify-between gap-5">
                    <Form.Item
                        label="Garanția Financiară (%)"
                        name="financialGarancy"
                        rules={[{ required: true, message: "Introduceți garanția" }]}
                    >
                        <Input placeholder="15" />
                    </Form.Item>
                    <Form.Item
                        label="Procente Beneficiar (%)"
                        name="beneficiar"
                        rules={[{ required: true, message: "Introduceți procente beneficiar" }]}
                    >
                        <Input placeholder="15" />
                    </Form.Item>
                </div>
                <Form.Item
                    label="Limbă Contract"
                    name="language"
                    rules={[{ required: true, message: "Selectați limba contractului" }]}
                    style={{ width: "200px" }}
                >
                    <Select placeholder="Selectați limba">
                        <Select.Option value="ro">RO</Select.Option>
                        <Select.Option value="ru">RU</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item>
                    <div className="flex items-center gap-2">
                        <Button type="primary" htmlType="submit" size="large">
                            Generează PDF
                        </Button>
                        <Button size="large" type="danger" onClick={onCancel}>
                            Închide
                        </Button>
                    </div>
                </Form.Item>
            </Form>
        </div>
    );
}
