import { Button, Card, Table } from "antd";
import moment from "moment";
import { useState } from "react";
import { BiEdit, BiPlus } from "react-icons/bi";
import { Link } from "react-router-dom";
import UserPrivateComponent from "../../PrivateRoutes/UserPrivateComponent";
import CreateOpportunityForm from "../Opportunity/CreateOpportunityForm";
import CreateDrawer from "./CreateDrawer";
import UpdateDrawer from "./UpdateDrawer";
import { TEXTS, CURRENCY } from "../../../constants";
import getRoleFromToken from "../../../utils/getRoleFromToken";

export default function Opportunities({
  data,
  loading,
  name,
  contact,
  singleLoadThunk,
}) {
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);

  const userRole = getRoleFromToken();

  // Roles that have access to all sections
  const allAccessRoles = ["Fondator", "Cofondator", "admin", "Administrator", "Manager Operațional"];

  // Function to check if the user has access to a menu item based on their role
  const hasAccess = (allowedRoles = []) => {
    // If allowedRoles includes 'all', everyone has access
    if (allowedRoles.includes("all")) {
      return true;
    }
    // If user's role is in the allowed roles or has full access, grant access
    return allowedRoles.includes(userRole) || allAccessRoles.includes(userRole);
  };

  const onClose = () => setOpen(false);
  const onCloseEdit = () => setEdit(false);

  // Helper function to calculate the total amount with discount
  const calculateTotalAmount = (opportunity) => {
    const totalAmount = opportunity?.offerAmount || 0; // Base amount of the opportunity
    const totalDiscount = opportunity?.quote?.reduce((discountSum, quote) => {
      return discountSum + (quote.discount || 0); // Summing up all discounts from quotes
    }, 0) || 0;

    // Euros: Subtract the discount from the total divided by EURO
    const totalInEuros = ((totalAmount / CURRENCY.EURO) - totalDiscount).toFixed(2);

    // Lei: Subtract the discount multiplied by EURO from the total amount
    const totalInLei = (totalAmount - (totalDiscount * CURRENCY.EURO)).toFixed(2);

    // Generate final string for both amounts
    return `${totalInEuros} € (${totalInLei} lei)`;
  };

  const columns = [
    {
      title: `${TEXTS.LABELS.LAST_NAME}`,
      key: "Opportunity Name",
      render: ({ opportunityName, id }) =>
        id ? (
          <Link to={`/admin/opportunity/${id}`}
            state={{ fromRoom: data.id, fromContact: contact }}
          >
            {opportunityName}
          </Link>
        ) : "-",
      sorter: (a, b) => a.opportunityName.localeCompare(b.opportunityName),
    },
    ...(hasAccess(["Tehnician Prețuri"])
      ? [
        {
          title: `${TEXTS.LABELS.AMOUNT_SUM}`,
          key: "amount",
          render: (opportunity) => calculateTotalAmount(opportunity),
        },
      ]
      : []),
    {
      title: `${TEXTS.LABELS.STAGE}`,
      key: "Stage",
      dataIndex: "opportunityStage",
      render: (field) => field?.opportunityStageName,
    },
    {
      title: `${TEXTS.TEXT.TYPE}`,
      dataIndex: "opportunityType",
      key: "opportunityType",
      render: (opportunityType) => opportunityType?.opportunityTypeName,
    },
    {
      title: `${TEXTS.TEXT.SOURCE}`,
      dataIndex: "opportunitySource",
      key: "opportunitySource",
      render: (opportunitySource) => opportunitySource?.opportunitySourceName,
    },
    {
      title: `${TEXTS.LABELS.CREATE_DATE}`,
      dataIndex: "createdAt",
      key: "createdAt",
      render: (date) => moment(date).format("MMMM Do YYYY HH:mm"),
    },
    {
      title: `${TEXTS.LABELS.ACTIONS}`,
      dataIndex: "id",
      key: "id",
      render: (id, opportunity) => (
        <span
          onClick={() => setEdit(opportunity)}
          className='bg-teal-500 p-1 cursor-pointer w-8 h-8 flex justify-center items-center rounded'
        >
          <BiEdit className='text-white' />
        </span>
      ),
    },
  ];

  return (
    <Card
      title={<span className='font-bold'>{TEXTS.LABELS.OPPORTUNITIES}</span>}
      extra={
        <UserPrivateComponent permission='create-opportunity'>
          <Button
            onClick={() => setOpen(true)}
            className='flex items-center'
            icon={<BiPlus />}
          >
            {TEXTS.BUTTON_TEXT.ADD_BTN}
          </Button>
        </UserPrivateComponent>
      }
      bodyStyle={{ padding: 0 }}
    >
      <div>
        <UserPrivateComponent permission='readAll-opportunity'>
          <Table
            bordered
            columns={columns}
            loading={loading}
            dataSource={data ? data.opportunity : []}
            pagination={{ hideOnSinglePage: true }}
            scroll={{ x: 800, y: 300 }}
          />
        </UserPrivateComponent>
      </div>

      <UserPrivateComponent permission='create-opportunity'>
        <CreateDrawer onClose={onClose} open={open} title={`${TEXTS.LABELS.OPPORTUNITY}`}>
          <CreateOpportunityForm
            onClose={onClose}
            open={open}
            createAs={{ name, value: data?.id, contact: contact, contactData: data?.contactId, singleLoadThunk }}
          />
        </CreateDrawer>
      </UserPrivateComponent>

      <UserPrivateComponent permission='update-opportunity'>
        <UpdateDrawer onClose={onCloseEdit} open={!!edit} title={`${TEXTS.LABELS.OPPORTUNITY}`}>
          <CreateOpportunityForm
            onClose={onCloseEdit}
            open={!!edit}
            edit={edit}
            singleLoad={{ thunk: singleLoadThunk, id: data?.id }}
          />
        </UpdateDrawer>
      </UserPrivateComponent>
    </Card>
  );
}
